<template>
  <!--Logo start-->
  <div class="logo-main">
    <img v-if="appName == 'Ixaya' || appName == ''" :class="`${color ? 'text-primary' : ''}`" :width="width" :height="height" :src="BASE_URL + '/logo_ixaya.svg'" alt="logo" />
    <img v-else :class="`${color ? 'text-primary' : ''}`" :width="width" :height="height" :src="BASE_URL + '/logo.svg'" alt="logo" />
  </div>
  <!--Logo End-->
</template>

<script>
// ===========================
// Imports
// ===========================
// Import required modules
import { computed } from 'vue'
import { useStore } from 'vuex'

// ===========================
// Props
// ===========================
export default {
  props: {
    color: { type: Boolean, default: false },
    width: { type: Number, default: 30 },
    height: { type: Number, default: 30 }
  },
  setup() {
    const store = useStore()
    const appName = computed(() => store.getters['setting/app_name'])
    const ENV = process.env.VUE_APP_ENV
    let BASE_URL = ''

    if (ENV === 'local') {
      BASE_URL = ''
    } else {
      BASE_URL = process.env.VUE_APP_BASE_URL
    }

    return { appName, BASE_URL }
  }
}
</script>
